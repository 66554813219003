export default {
    state: {
        menu: [

            { product: true, title: 'Internal Audit', bgcolor: 'crisk' },
            {
                title: 'Project List', icon: 'mdi-dns-outline', href: '/ia/project',
            },
            
            
        ],
    },
    mutations: {
    },
    actions: {
    },
    getters: {

    },
}